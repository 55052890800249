import React, { useState, useEffect } from 'react';
import config from '../../../config';
import TreeNode from './treeNode';

const getCollapsedForLocation = (collapsed, treeData, location) => {
  const result = {
    ...collapsed
  };

  const urlParts = location.pathname.replace(config.gatsby.pathPrefix, '').split('/')
  let tmp = ''
  for (let i = 1; i < urlParts.length - 1; i += 1) {
    tmp = tmp + '/' + urlParts[i]
    result[tmp + (config.gatsby.trailingSlash ? '/' : '')] = false;
  }
  result[undefined] = false;

  return result;
}


const Tree = ({ treeData, showCollapseButton, location }) => {
  const [collapsed, setCollapsed] = useState({});

  useEffect(() => {
    setCollapsed(getCollapsedForLocation(showCollapseButton ? collapsed : {}, treeData, location))
  }, [location.pathname])

  const toggle = url => {
    const collapsedForUrl = (collapsed[url] === undefined) ? true : collapsed[url]

    setCollapsed({
      ...collapsed,
      [url]: !collapsedForUrl,
    });
  };

  return (
    <TreeNode
      className={`${config.sidebar.frontLine ? 'showFrontLine' : 'hideFrontLine'} firstLevel`}
      setCollapsed={toggle}
      collapsed={collapsed}
      showCollapseButton={showCollapseButton}
      curLocation={location}
      {...treeData}
    />
  );
};

export default Tree;
