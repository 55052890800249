import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import config from '../../../config';

import isAbsoluteUrl from 'is-absolute-url';

function isInternalLink(url) {
  if (isAbsoluteUrl(url)) {
    return false;
  }

  if (url.endsWith('.pdf')) {
    return false;
  }

  if (url.endsWith('.json')) {
    return false;
  }

  if (url.endsWith('.gpkg')) {
    return false;
  }

  return true;
}

const AnchorTag = ({ children: link, ...props }) => {
  if (!link) {
    console.log("Missing link; returning null")
    return null;
  }

  if (isInternalLink(props.href)) {
    const to = props.href.replace(config.gatsby.pathPrefix, '')
    return <GatsbyLink to={to}>{link}</GatsbyLink>
  }

  return <a href={props.href} target="_blank" rel="noopener noreferrer">{link}</a>;
};

export default AnchorTag;
