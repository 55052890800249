import * as React from 'react';
import styled from '@emotion/styled';

import CodeBlock from './codeBlock';
import AnchorTag from './anchor';

const StyledHeader = (x) => styled(`h${x}`)`
  a:hover {
    text-decoration: underline;
  }

  a {
    color: ${(props) => props.theme.colors.text};
  }
`
const StyledH1 = StyledHeader(1)
const StyledH2 = StyledHeader(2)
const StyledH3 = StyledHeader(3)
const StyledH4 = StyledHeader(4)
const StyledH5 = StyledHeader(5)
const StyledH6 = StyledHeader(6)

const StyledPre = styled('pre')`
  padding: 16px;
  background: ${(props) => props.theme.colors.preFormattedText};
`;

export default {
  h1: (props) => <StyledH1 className="heading1" {...props}><a href={`#${props.id}`}>{props.children}</a></StyledH1>,
  h2: (props) => <StyledH2 className="heading2" {...props}><a href={`#${props.id}`}>{props.children}</a></StyledH2>,
  h3: (props) => <StyledH3 className="heading3" {...props}><a href={`#${props.id}`}>{props.children}</a></StyledH3>,
  h4: (props) => <StyledH4 className="heading4" {...props}><a href={`#${props.id}`}>{props.children}</a></StyledH4>,
  h5: (props) => <StyledH5 className="heading5" {...props}><a href={`#${props.id}`}>{props.children}</a></StyledH5>,
  h6: (props) => <StyledH6 className="heading6" {...props}><a href={`#${props.id}`}>{props.children}</a></StyledH6>,
  p: (props) => <p className="paragraph" {...props} />,
  pre: (props) => <StyledPre><pre {...props} /></StyledPre>,
  code: CodeBlock,
  a: AnchorTag,
  // TODO add `img`
  // TODO add `blockquote`
  // TODO add `ul`
  // TODO add `li`
  // TODO add `table`
};
