import * as React from 'react';
import OpenedSvg from '../images/opened';
import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from '../link';

const TreeNode = ({ className = '', setCollapsed, collapsed, showCollapseButton, curLocation, url, title, items }) => {
  const isCollapsed = collapsed[url] !== undefined ? collapsed[url] : true;

  const collapse = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setCollapsed(url);
  };

  const hasChildren = items && items.length !== 0;

  const active = (curLocation.pathname === url || curLocation.pathname === config.gatsby.pathPrefix + url);

  const calculatedClassName = `${className} item ${active ? 'active' : ''}`;

  return (
    <li className={calculatedClassName}>
      {title && (
        <Link to={url}>
          {title}
          {showCollapseButton && !config.sidebar.frontLine && title && hasChildren ? (
            <button onClick={collapse} aria-label="collapse" className="collapser">
              {!isCollapsed ? <OpenedSvg /> : <ClosedSvg />}
            </button>
          ) : null}
        </Link>
      )}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              showCollapseButton={showCollapseButton}
              curLocation={curLocation}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default TreeNode;
