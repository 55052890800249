const PRODUCTION_CONFIG = {
  pathPrefix: '/api/latest',
  siteUrl: 'https://docs.sentinel-hub.com',
  baseCanonicalUrl: 'https://docs.sentinel-hub.com/api/latest',
  fathomTracking: true,
  ignoreFiles: ["**/draft-*", "**/early-access-*/**"],
  search: {
    enabled: true,
    indexName: 'prod_docs',
  },
}

const EARLY_ACCESS_CONFIG = {
  pathPrefix: '/api/early-access',
  siteUrl: 'https://docs.sentinel-hub.com',
  baseCanonicalUrl: 'https://docs.sentinel-hub.com/api/latest',
  fathomTracking: false,
  ignoreFiles: ["**/draft-*"],
  search: {
    enabled: true,
    indexName: 'early_access_docs',
  },
}

const STAGE_CONFIG = {
  pathPrefix: '/api/stage',
  siteUrl: 'https://docs.sentinel-hub.com',
  baseCanonicalUrl: 'https://docs.sentinel-hub.com/api/latest',
  fathomTracking: false,
  ignoreFiles: ["**/early-access-*/**"],
  search: {
    enabled: true,
    indexName: 'stage_docs',
  },
}

const ACTIVE_CONFIG_MAPPER = {
  "production": PRODUCTION_CONFIG,
  "early_access": EARLY_ACCESS_CONFIG,
  "stage": STAGE_CONFIG
}

const ACTIVE_CONFIG = ACTIVE_CONFIG_MAPPER[process.env.GATSBY_DEPLOY_ENV] || STAGE_CONFIG

const config = {
  gatsby: {
    ...ACTIVE_CONFIG,
    trailingSlash: true,
  },
  fathomTracking: {
    enabled: ACTIVE_CONFIG.fathomTracking,
    dataSite: process.env.GATSBY_FATHOM_TRACKING_DATASITE,
  },
  header: {
    logo: '',
    logoLink: '/',
    title: '',
    helpUrl: '',
    tweetText: '',
    social: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: ACTIVE_CONFIG.search.enabled,
      indexName: ACTIVE_CONFIG.search.indexName,
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
      minNumOfCharacters: 3,
      delayBeforeSearch: 200,
    },
  },
  sidebar: {
    forcedNavOrder: [],
    links: [{ text: 'API Reference', link: 'https://docs.sentinel-hub.com/api/latest/reference/' }],
    frontline: false,
    title: '',
  },
  rightSidebar: {
    maxDepth: 3,
  },
  siteMetadata: {
    title: 'Gatsby Gitbook Boilerplate | Hasura',
    description: 'Documentation built with mdx. Powering hasura.io/learn ',
    ogImage: 'https://www.sentinel-hub.com/img/og-image/satellite.png',
  },
};

module.exports = config;
