import * as React from "react";
import { useEffect, useRef } from "react";

const JSONFormatter =
  typeof window !== `undefined` ? require("json-formatter-js") : null;

const style = {
  padding: 16,
  backgroundColor: "rgb(30, 30, 30)",
};

const ReactJsonViewer = ({ code, open }) => {
  const elRef = useRef(null);

  useEffect(() => {
    if (elRef.current !== null) {
      const myJSON = JSON.parse(code);
      const formatter = new JSONFormatter(myJSON, open, { theme: "dark" });
      elRef.current.appendChild(formatter.render());
    }
  }, [code, open]);

  return <div style={style} ref={elRef} />;
};

export default ReactJsonViewer;
